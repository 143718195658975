/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";

const TermsPage = () => (
    <Layout>
    <SEO title="Terms and Conditions" />
    <Container className="text-page">
      <Row>
        <Col>
        <h2><strong>Terms and Conditions</strong></h2>

            <p>You are using a website operated by LUCY MOUNTAIN LIMITED.  Referred to as “Nobs App” or “we” in this agreement. By accessing or using the Nobs App Website, the Nobs App Service, or any applications (including mobile applications) made available by Nobs App (together, the “Service”), however accessed, you agree to be bound by the following terms and conditions which consists of the following policies:</p>
            <ol>
                <li>General Terms of Use</li>
                <li>Acceptable Use</li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ol>
            <p>(and together, are the &ldquo;Terms and Conditions&rdquo;).</p>
            <p>The Service is owned or controlled by Nobs App. These Terms and Conditions affect your legal rights and obligations. If you do not agree to be bound by all of these Terms and Conditions, do not access or use the Service.</p>
            <p>There may be times when we offer a special feature that has its own terms and conditions that apply in addition to these Terms and Conditions. In those cases, the terms specific to the special feature shall apply to the extent there is a conflict with these Terms and Conditions.</p>
            <h3><strong>Contact Nobs App</strong></h3>
            <p>If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints, claims or other legal concerns relating to Nobs App or its business, please contact support@nobsapp.zendesk.com.</p>
            <h3><strong>Definitions</strong></h3>
            <ol>
                <li>-&ldquo;Account&rdquo; &ndash; a registered user account with Nobs App Ltd.</li>
                <li>“Nobs App” is the application owned and distributed by Nobs App Ltd.</li>
                <li>“Nobs App Service” – Nobs App Provides the service of a platform where users can share content and ideas.</li>
                <li>Nobs App website - nobsapp.co.uk</li>
                <li>“Content” means any data, text, files, information, usernames, images, graphics, photos, profiles, links and other content or materials.</li>
                <li>“Product” means any digital or physical fitness or related product purchased through the Nobs App website or Nobs App Applications.</li>
                <li>“Seller” – means the brand you are purchasing from.</li>
                <li>“Software” means the Nobs App application that a user can use on a computer or supported mobile device in order to utilize the Services; the Software may be downloaded or accessed on the Internet.</li>
            </ol>
            <h3><strong>General Terms of Use</strong></h3>
            <ol>
                <li>You must create an Account in order to use the Service.</li>
                <li>You must be at least 16 years old to use the Service.</li>
                <li>You must use the Nobs App website and Service only in accordance with our Acceptable Use Policy.</li>
                <li>You are responsible for any activity that occurs through your Account and you agree you will not sell, transfer, license or assign your account, followers, username, or any Account rights. With the exception of people or businesses that are expressly authorized to create Accounts on behalf of their employers or clients, Nobs App prohibits the creation of and you agree that you will not create an account for anyone other than yourself. You also represent that all information you provide or provided to Nobs App upon registration and at all other times will be true, accurate, current and complete and you agree to update your information as necessary to maintain its truth and accuracy.</li>
                <li>You agree that you will not solicit, collect or use the login credentials of other Nobs App users.</li>
                <li>You are responsible for keeping your password secret and secure.</li>
                <li>Any links shared on Nobs App are your responsibility and we take no responsibility for any third party links shared on the Nobs App website. If we find that any third party links you have posted do not comply with our Terms and Conditions, you will be removed from the Service.</li>
                <li>You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or entities and you must not post private or confidential information via the Service. If found doing so we would delete your account and report you to the relevant authorities.</li>
                <li>You may not use the Service for any illegal or unauthorised purpose.</li>
                <li>You are solely responsible for your conduct and Content that you submit, post or display on or via the Service.</li>
                <li>You must not change, modify, adapt or alter the Service or change, modify or alter another website so as to falsely imply that it is associated with the Service or Nobs App.</li>
            </ol>
            <p>You must not create or submit unwanted email, comments, likes or other forms of commercial or harassing communications (a/k/a &ldquo;spam&rdquo;) to any Nobs App users.</p>
            <h3><strong>Rights &amp; Ownership</strong></h3>
            <ol>
                <li>Nobs App does not claim ownership of any Content that you post on or through the Service. Instead, you hereby grant to Nobs App a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to use the Content that you post on or through the Service, subject to our Privacy Policy.</li>
                <li>You acknowledge that we may not always identify paid services, sponsored content, or commercial communications as such.</li>
                <li>You represent and warrant that: (i) you own the Content posted by you on or through the Service or otherwise have the right to grant the rights and licenses set forth in these Terms and Conditions; (ii) the posting and use of your Content on or through the Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; (iii) you agree to pay for all royalties, fees, and any other monies owed by reason of Content you post on or through the Service; and (iv) you have the legal right and capacity to enter into these Terms and Conditions in your jurisdiction.</li>
                <li>The Service contains Content owned or licensed by Nobs App (“Nobs App Content”). Nobs App Content is protected by copyright, trademark, patent, trade secret and other laws, and, as between you and Nobs App, Nobs App Owns and retains all rights in the Nobs App Content and the Service. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Nobs App Content and you will not reproduce, modify, adapt, prepare derivative works based on, perform, display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit the Nobs App Content.</li>
                <li>The Nobs App name and logo are trademarks of Nobs App, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Nobs App. In addition, all page headers, custom graphics, button icons and scripts are service marks, trademarks and/or trade dress of Nobs App, and may not be copied, imitated or used, in whole or in part, without prior written permission from Nobs App.</li>
                <li>You agree that Nobs App is not responsible for, and does not endorse nor monitor, any third party Content posted within the Service. Nobs App Does not have any obligation to pre-screen, monitor, edit, or remove any third party Content. If your Content violates these Terms and Conditions, you may bear legal responsibility for that Content.</li>
            </ol>
            <h3><strong>App Payments</strong></h3>
            <p>All app subscription payments are handled through the Apple and Google app stores and are under their terms and conditions. All web subscription payments are handled by Stripe and are bound by these terms and conditions.</p>
            <h3><strong>Subscription Info</strong></h3>
            <p>The download of Nobs app is free of charge. Once users register they will have to subscribe in order to get access to the app content. We offer the following auto-renewing subscriptions:</p>
            <ul>
                <li>&pound;11.99 monthly</li>
                <li>&pound;31.99 quarterly</li>
                <li>&pound;119.99 yearly</li>
            </ul>
            <p>Other currencies are set by Apple or Google directly. For payments made via web, conversion to your home currency will be handled by your account provider and a conversion fee may be charged for this process.</p>
            <p>Subscribing will give you access to all features the app offers.</p>
            <ul>
                <li>If you decide to subscribe, you agree to instantly pay the price shown upon confirmation of purchase.</li>
                <li>Payment will be charged to the payment account linked to the card (if you are subscribing via web) or account you are using (the Apple or Google account connected to your device).</li>
                <li>If it is not cancelled earlier on, a subscription will automatically renew within 24 hours before the end of the current subscription period.</li>
                <li>Your account will be charged for renewal within 24 hours before the end of the current subscription period.</li>
                <li>You have the right to manage your subscription and auto-renewal may be turned off at any point after purchase.</li>
                <li>The amount you paid for your subscription upon purchase is non-refundable even if the subscription is terminated early.</li>
            </ul>

            <h4><strong>RETURNS</strong></h4>
            <h5><strong>Refund and Returns Policy</strong></h5>
            <p>This Refund and Returns Policy applies for all products purchased through the Nobs App checkout.</p>
            <p>You acknowledge that Nobs App provides a platform for users to purchase Products from a Seller, and that a sales contract is formed between you and a Seller only; Nobs App is not part of that transaction.</p>
            <p>If you purchase a Product from the Nobs App checkout, you understand that:</p>
            <ol>
                <li>you are not buying a Product directly from Nobs App, but from the Seller;</li>
                <li>you may contact Nobs App directly about your purchase; and</li>
                <li>delivery of a Product is handled by a third party;</li>
                <li>On all digital content you will lose your 14 day right to cancel as you can instantly see the content upon purchase.</li>
            </ol>
            <p>After making a purchase, you will be sent confirmation of the sales contract between the Seller and yourself via email promptly after the conclusion of the contract.&nbsp;</p>
            <p>You acknowledge that Products purchased through the Nobs App software are deemed to fall within the low value off-premises contract exemption to The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013.</p>
            <p>You may be requested to provide proof of purchase when requesting a refund.</p>
            <p>If you have any questions, please email support@lisafitt.zendesk.com.</p>
            <h5><strong>RETURNS FROM THE POSTAL SERVICE</strong></h5>
            <p>If your order is returned to us due to non-delivery or incorrect address, it will be automatically cancelled and refunded and you will need to create another order again. You will not be refunded for the postage as we have incurred the cost sending you the order.</p>
            <h5><strong>RETURNS BY CUSTOMER</strong></h5>
            <p>Any returned parcels sent by the customer without acknowledgment from a member of the support team will not be returned/exchanged or refunded.</p>
            <h4><strong>DIGITAL PRODUCTS</strong></h4>
            <p>You acknowledge that the Seller is not obliged to provide a refund on digital Products (which includes all guides) purchased through the Nobs App Software.</p>
            <p>Once a download of a digital product has started, you do not have a right to cancel the purchase and to request a refund as per Gov UK rules.</p>
            <p>Refunds for digital products shall only be given in the following limited circumstances:</p>
            <ol>
                <li>If there was a technical issue and the content was never received by the buyer.</li>
                <li>If the transaction was not a genuine transaction (e.g. card fraud etc)</li>
                <li>If there was a duplicate order or payment due to a technical fault. Genuine multiple orders by the same person are not eligible for a refund.</li>
            </ol>
            <h4><strong>PHYSICAL PRODUCTS&nbsp;</strong></h4>
            <p>You acknowledge that the Seller is not obliged to provide a refund on physical Products purchased through the Nobs App Software.</p>
            <p>Refunds for physical Products shall only be given in the following circumstances:</p>
            <ol>
                <li>If the item was ripped or broken which made it non-functioning.</li>
                <li>If the transaction was not a genuine transaction (card fraud etc)</li>
                <li>If there was a duplicate order or payment due to a technical fault. Genuine multiple orders by the same person are not eligible for a refund.</li>
            </ol>
            <h4><strong>FAULTY PRODUCTS&nbsp;</strong></h4>
            <p>If you have received a faulty product and it's within 60 days from purchase please send in a picture of the faulty product and we can exchange this but unfortunately, there will be no refunds.</p>
            <h4><strong>Delivery of Products</strong></h4>
            <p>The Nobs App Software uses Stripe and PayPal as a third party payment providers. Nobs App does not hold any payment details of users.</p>
            <p>All Products purchased through the Nobs App Software are delivered via Royal Mail or another courier. All deliver information is listed on the website prior to purchase and a customer&rsquo;s delivery information is listed in the confirmation email.</p>
            <p>When your item is dispatched you will receive an email with information on how long the delivery will take and what service was used. If the delivery was tracked a tracking number and link will also be provided.</p>
            <p>You also may receive a text message once your order is dispatched however this is dependent on you adding your phone number when completing your order and if your mobile carrier supports our service.</p>
            <p>All delivery of products is handled by a third party company.</p>
            <h4><strong>In Stock Items</strong></h4>
            <p>At the time of purchase, you will be informed via email from Nobs App regarding the anticipated dates of your delivery.</p>
            <h4><strong>Pre-Order Items</strong></h4>
            <p>For all pre-order sales (which shall be clearly marked as such), delivery dates are estimates only as the Product is still yet to be manufactured. You agree that you are purchasing a Product from the Seller which will only be delivered once it has been produced and that you will be notified via email when your Product has been dispatched.</p>

            <h3><strong>Disclaimer</strong></h3>
            <p>The Nobs App App makes no warranties or representations, express or implied, as to the currency, accuracy, completeness, reliability or suitability of the information contained or referenced in this app. The information is subject to professional differences of opinion, human error in preparing this information. The Nobs App Plans is not liable for any loss resulting from any action taken or reliance made by you on any of the information or material contained in the ebook. If you use, or otherwise rely on, any of the information in the app you are responsible for ensuring, by independent verification, its currency, accuracy, completeness, reliability and relevance to your own personal and individual circumstances.&nbsp;</p>
            <h3><strong>Refund Policy</strong></h3>
            <p>As Nobs App is a digital product, as soon as you subscribe you have access to all the content available on the app. Therefore, you hereby acknowledge that your purchase cannot be cancelled and that the Seller is not obliged to provide you with a refund.</p>
            <p><b>Refunds for the Product shall only be given under exceptional circumstances:</b></p>
            <ul>
                <li>If there is a technical issue that the Seller is responsible for, which has prevented you from ever accessing the Product.</li>
                <li>If a duplicate subscription payment is made due to a technical fault. Genuine multiple orders by the same person are not eligible for a refund.</li>
                <li>If your subscription payment was not a genuine transaction (e.g. card fraud etc).</li>
            </ul>
            <p><b>Change of Mind &amp; Early Termination:</b></p>
            <p>Even if you made an accidental purchase, changed your mind, or realised that there are legitimate reasons why the Product is not suitable for you, we will not be able to refund you. Terminating your subscription early does not give you the right to a refund of any unused portion of your subscription, even if you stop accessing the app before the date when your subscription was set to expire.</p>
            <p><b>Technical Issues:</b></p>
            <p>The Product may be likely to present technical issues which relate to your device and operating software, or other applications you have installed which can interfere with the Product&rsquo;s performance. Issues of this nature are not necessarily the result of an oversight during the Product&rsquo;s development and may therefore often be out of the Seller&rsquo;s control. Nonetheless, the Seller should always strive to rectify these issues in a timely manner to ensure the Product&rsquo;s optimum performance for all customers. If the Seller is actively trying to resolve these technical issues, and these issues are not detrimental to the overall usage of the Product, experiencing them will not constitute grounds for a refund.</p>
            <h3><strong>Acceptable Use Policy</strong></h3>
            <p>This acceptable use policy sets out the terms between you and us under which you may access the Service. This acceptable use policy applies to all users of, and visitors to, the Service.</p>
            <p>Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable use policy, which form part of our Terms and Conditions.</p>
            <h3><strong>Prohibited uses</strong></h3>
            <p>You may use our site only for lawful purposes. You may not use our site:</p>
            <ul>
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                <li>For the purpose of harming or attempting to harm minors in any way.</li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
            </ul>
            <p>You also agree:</p>
            <ul>
                <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our General Terms of Use.</li>
                <li>Not to access without authority, interfere with, damage or disrupt:</li>
                <li>any part of our site;</li>
                <li>any equipment or network on which our site is stored;</li>
                <li>any software used in the provision of our site; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
                <li>Not to abuse our staff through emails and social media. Any form of abuse will result in your account being blocked.</li>
            </ul>
            <p>We will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).</p>
            <p>We will do our best to assess any possible risks for users (and in particular, for children) from third parties when they use any interactive service provided on our site, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the service is moderated or not.</p>
            <p>The use of any of our interactive services by a minor is subject to the consent of their parent or guardian. We advise parents who permit their children to use an interactive service that it is important that they communicate with their children about their safety online, as moderation is not foolproof. Minors who are using any interactive service should be made aware of the potential risks to them. Content standards</p>
            <p>These content standards apply to any and all material which you contribute to our site (contributions), and to any interactive services associated with it.</p>
            <p>You must comply with the spirit and the letter of the following standards. The standards apply to each part of any contribution as well as to its whole.</p>
            <p>Contributions must:</p>
            <ul>
                <li>Be accurate (where they state facts).</li>
                <li>Be genuinely held (where they state opinions).</li>
                <li>Comply with applicable law in the UK and in any country from which they are posted.</li>
            </ul>
            <p>Contributions must not:</p>
            <ul>
                <li>Contain any material which is defamatory of any person.</li>
                <li>Contain any material which is obscene, offensive, hateful or inflammatory.</li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                <li>Infringe any copyright, database right or trademark of any other person.</li>
                <li>Be likely to deceive any person.</li>
                <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                <li>Promote any illegal activity.</li>
                <li>Be threatening, abuse or invade another&rsquo;s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.</li>
                <li>Give the impression that they emanate from us, if this is not the case.</li>
                <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
            </ul>
            <h3><strong>Suspension and termination</strong></h3>
            <p>We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of our site. When a breach of this policy has occurred, we may take such action as we deem appropriate.</p>
            <p>Failure to comply with this acceptable use policy constitutes a material breach of our Terms and Conditions upon which you are permitted to use our site, and may result in our taking all or any of the following actions:</p>
            <ul>
                <li>Immediate, temporary or permanent withdrawal of your right to use our site.</li>
                <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to our site.</li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</li>
            </ul>
            <p>We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.</p>
            <h3><strong>Changes to the acceptable use policy</strong></h3>
            <p>We may revise this acceptable use policy at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this acceptable use policy may also be superseded by provisions or notices published elsewhere on our site.</p>
            <h3><strong>Contact Nobs App</strong></h3>
            <p>If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints, claims or other legal concerns relating to Nobs App or its business, please contact support@nobsapp.zendesk.com</p>


        </Col>
      </Row>
    </Container>
    </Layout>
);

export default TermsPage;
